import {
    CHANGE_CUSTOMER_ID,
    LOGIN_USER_FAILURE,
    LOGIN_USER_PENDING,
    LOGIN_USER_SUCCESSFUL,
    RESET_AUTH,
    SET_AUTH_USER_BEARER_TOKEN
} from "../action-types/auth";

const initialState = {
    user: null,
    error: null,
    pending: false
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                pending: false
            };
        case LOGIN_USER_PENDING:
            return {
                ...state,
                pending: true
            };
        case LOGIN_USER_SUCCESSFUL:
            return {
                ...state,
                user: action.user,
                pending: false,
                error: initialState.error
            };
        case SET_AUTH_USER_BEARER_TOKEN:
            return {
                ...state,
                user: {...state.user, bearerToken: action.bearerToken}
            };
        case CHANGE_CUSTOMER_ID:
            return state.user ? {
                ...state,
                user: {...state.user, customerId: action.customerId}
            } : {...state};
        case RESET_AUTH:
            return initialState;
        default:
            return state;
    }
};