import {get} from '../common'
import {store} from '../../redux/store';

export const getApiUrl = () => {
    const appSettings = store.getState().appSettings;
    // Fixes port 80 issue in IIS
    const apiPort = appSettings.apiPort === 80 ? '' : `:${appSettings.apiPort}`;
    return `${appSettings.apiProtocol}${appSettings.apiHostname}${apiPort}${appSettings.apiBasePath}`;
}

export const getAuthToken = () => {
    const auth = store.getState().auth;
    return get(auth, 'bearerToken', false);
}