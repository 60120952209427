import {httpService} from './httpService';

export class settingsService extends httpService {
    _requestPath = '/settings';

    constructor() {
        super();
    }

    async getIcons() {
        try {
            return await this.httpGet(`/icons`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

}