export const RESET_CUSTOMERS = 'RESET_CUSTOMERS';
export const GET_CUSTOMERS_PENDING = 'GET_CUSTOMERS_PENDING';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';
export const GET_CUSTOMERS_SUCCESSFUL = 'GET_CUSTOMERS_SUCCESSFUL';

export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const CREATE_CUSTOMER_SUCCESSFUL = 'CREATE_CUSTOMER_SUCCESSFUL';

export const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_SUCCESSFUL = 'UPDATE_CUSTOMER_SUCCESSFUL';

export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_SUCCESSFUL = 'DELETE_CUSTOMER_SUCCESSFUL';
