import {httpService} from './httpService';

export class sensorWatchService extends httpService {
    _requestPath = '/sensorWatches';

    constructor() {
        super();
    }

    async get(id) {
        try {
            return await this.httpGet(`/${id}`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getList(name, customerId = -1, pageIndex = 0, pageSize = 30) {
        try {
            return await this.httpGet(`/?filter=${name}&customerId=${customerId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getTriggers() {
        try {
            return await this.httpGet(`/triggers`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async getRules() {
        try {
            return await this.httpGet(`/rules`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async create(sensorWatch) {
        try {
            return await this.httpPost(sensorWatch);
        }
        catch (e) {
            return e;
        }
    }

    async update(sensorWatch) {
        try {
            return await this.httpPut(sensorWatch);
        }
        catch (e) {
            return e;
        }
    }

    async delete(id) {
        try {
            return await this.httpDelete({}, `/${id}`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

}