import {httpService} from './httpService';

export class sensorNodeService extends httpService {
    _requestPath = '/sensorNodes';

    constructor() {
        super();
    }

    async get(id = null) {
        try {
            return await this.httpGet(`/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getSensors(id = null, customerId = null) {
        try {
            return await this.httpGet(`/${id}/sensors?customerId=${customerId}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
    async search(name, customerId = null, pageIndex = 0, pageSize = 30) {
        try {
            return await this.httpGet(`/search?sensorNodeName=${name}&customerId=${customerId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getKnownTypes(customerId, pageIndex = 0, pageSize = 30) {
        try {
            return await this.httpGet(`/knownTypes?customerId=${customerId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async exportByNodeId(id = null, startDate, endDate, onProgress) {
        try {
            return await this.httpGet(
                `/${id}/export?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`)
                .on('progress', event => {
                    if(typeof onProgress === 'function')
                        onProgress(event);
                })
                .responseType('blob');
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }
    
    async resetType(id) {
        try {
            return await this.httpPut(null, `/${id}/resetType`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
}