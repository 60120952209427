import {
    SET_BACKGROUND_COLOR,
    SET_BACKGROUND_IMAGE,
    SET_BACKGROUND_IMAGE_OPACITY,
    SET_COLOR_SCHEME,
    SET_ENABLE_BACKGROUND_IMAGE,
    SET_ENABLE_CLOSED_SIDEBAR,
    SET_ENABLE_FIXED_FOOTER,
    SET_ENABLE_FIXED_HEADER,
    SET_ENABLE_FIXED_SIDEBAR,
    SET_ENABLE_HEADER_SHADOW,
    SET_ENABLE_MOBILE_MENU,
    SET_ENABLE_MOBILE_MENU_SMALL,
    SET_ENABLE_PAGE_TABS_ALT,
    SET_ENABLE_PAGETITLE_ICON,
    SET_ENABLE_PAGETITLE_SUBHEADING,
    SET_ENABLE_SIDEBAR_SHADOW,
    SET_HEADER_BACKGROUND_COLOR,
    SET_THEME_OPTIONS_ICON_COLORS,
    SET_THEME_OPTIONS_TASK_FAILURE,
    SET_THEME_OPTIONS_TASK_PENDING,
    SET_THEME_OPTIONS_TASK_SUCCESSFUL
} from "../action-types/themeOptions";

//TODO: Replace with api request when ready.
const colors = [
    {code: "#222222", name: "Svart"},
    {code: "#ffffff", name: "Vit"},
    {code: "#efefef", name: "Ljusgrå"},
    {code: "#aaaaaa", name: "Mörkgrå"},
    {code:"#f22121", name: "Röd"},
    {code: "#bd47f8", name: "Lila"},
    {code: "#f86fcb", name: "Rosa"},
    {code: "#faa267", name: "Orange"},
    {code: "#edf76a", name: "Lime"},
    {code: "#82ed9b", name: "Ljusgrön"},
    {code: "#44af5d", name: "Mörkgrön"},
    {code: "#62d1c9", name: "Turkos"},
    {code: "#89eaf5", name: "Ljusblå"},
    {code: "#2253f6", name: "Mörkblå"},
    {code: "#448811", name: "Mörkgrön2"},
    {code: "#6FA222", name: "Grön"},
    {code: "#99bb33", name: "Ljusgrön2"},
    {code: "#CCBE1D", name: "Gulgrön"},
    {code: "#ffc107", name: "Gul"},
    {code: "#ff7700", name: "Orange2"},
    {code: "#D53C00", name: "Mörkorange"},
    {code: "#aa0000", name: "Mörkröd"}
];

export const getIconColors = () => {
    return async (dispatch, getState) => {
        dispatch(setThemeOptionsTaskPending());
        // TODO: API request for colors goes here when ready
        if(1) {
            dispatch(setThemeOptionIconColors(colors));
            dispatch(setThemeOptionsTaskSuccessful());
        } else {
            dispatch(setThemeOptionsTaskFailure(`Failed to get/set icon colors`));
        }
    };
};

export const setEnableBackgroundImage = enableBackgroundImage => ({
    type: SET_ENABLE_BACKGROUND_IMAGE,
    enableBackgroundImage
});

export const setThemeOptionIconColors = iconColors => ({
    type: SET_THEME_OPTIONS_ICON_COLORS,
    iconColors: iconColors
});

export const setEnableFixedHeader = enableFixedHeader => ({
    type: SET_ENABLE_FIXED_HEADER,
    enableFixedHeader
});

export const setEnableHeaderShadow = enableHeaderShadow => ({
    type: SET_ENABLE_HEADER_SHADOW,
    enableHeaderShadow
});

export const setEnableSidebarShadow = enableSidebarShadow => ({
    type: SET_ENABLE_SIDEBAR_SHADOW,
    enableSidebarShadow
});

export const setEnablePageTitleIcon = enablePageTitleIcon => ({
    type: SET_ENABLE_PAGETITLE_ICON,
    enablePageTitleIcon
});

export const setEnablePageTitleSubheading = enablePageTitleSubheading => ({
    type: SET_ENABLE_PAGETITLE_SUBHEADING,
    enablePageTitleSubheading
});

export const setEnablePageTabsAlt = enablePageTabsAlt => ({
    type: SET_ENABLE_PAGE_TABS_ALT,
    enablePageTabsAlt
});

export const setEnableFixedSidebar = enableFixedSidebar => ({
    type: SET_ENABLE_FIXED_SIDEBAR,
    enableFixedSidebar
});

export const setEnableClosedSidebar = enableClosedSidebar => ({
    type: SET_ENABLE_CLOSED_SIDEBAR,
    enableClosedSidebar
});

export const setEnableMobileMenu = enableMobileMenu => ({
    type: SET_ENABLE_MOBILE_MENU,
    enableMobileMenu
});

export const setEnableMobileMenuSmall = enableMobileMenuSmall => ({
    type: SET_ENABLE_MOBILE_MENU_SMALL,
    enableMobileMenuSmall
});

export const setEnableFixedFooter = enableFixedFooter => ({
    type: SET_ENABLE_FIXED_FOOTER,
    enableFixedFooter
});

export const setBackgroundColor = backgroundColor => ({
    type: SET_BACKGROUND_COLOR,
    backgroundColor
});

export const setHeaderBackgroundColor = headerBackgroundColor => ({
    type: SET_HEADER_BACKGROUND_COLOR,
    headerBackgroundColor
});

export const setColorScheme = colorScheme => ({
    type: SET_COLOR_SCHEME,
    colorScheme
});

export const setBackgroundImageOpacity = backgroundImageOpacity => ({
    type: SET_BACKGROUND_IMAGE_OPACITY,
    backgroundImageOpacity
});

export const setBackgroundImage = backgroundImage => ({
    type: SET_BACKGROUND_IMAGE,
    backgroundImage
});

export const setThemeOptionsTaskPending = () => ({
    type: SET_THEME_OPTIONS_TASK_PENDING
});

export const setThemeOptionsTaskFailure = (error) => ({
    type: SET_THEME_OPTIONS_TASK_FAILURE,
    error: error
});

export const setThemeOptionsTaskSuccessful = () => ({
    type: SET_THEME_OPTIONS_TASK_SUCCESSFUL
});