import {httpService} from './httpService';

export class widgetService extends httpService {
    _requestPath = '/widgets';

    constructor() {
        super();
    }

    async get(id) {
        try {
            return await this.httpGet(`/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getWidgetSettings(sensorId) {
        try {
            return await this.httpGet(`/types/info/${sensorId}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getInitializeValues(searchFilter) {
        try {
            return await this.httpPost(searchFilter, `/values/initialize`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getWidgetTypesInfo() {
        try {
            return await this.httpGet( `/types/info`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
    async getWidgetTypesComponentNames() {
        try {
            return await this.httpGet( `/types/component-names`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async create(dashboardId, sensorId, widgetTypeId, sortIndex, title, unitOfMeasurement, updateFrequency) {
        try {
            const createWidget = {
                dashboardId: dashboardId,
                sensorId: sensorId,
                widgetTypeId: widgetTypeId,
                sortIndex: sortIndex,
                title: title,
                unitOfMeasurement: unitOfMeasurement,
                updateFrequency: updateFrequency
            };
            return await this.httpPost(createWidget);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async update(id, dashboardId, sensorId, widgetTypeId, sortIndex, title, unitOfMeasurement, updateFrequency) {
        try {
            const updateWidget = {
                dashboardId: dashboardId,
                sensorId: sensorId,
                widgetTypeId: widgetTypeId,
                sortIndex: sortIndex,
                title: title,
                unitOfMeasurement: unitOfMeasurement,
                updateFrequency: updateFrequency
            };
            return await this.httpPut(updateWidget, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async delete(id) {
        try {
            return await this.httpDelete({}, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
}