import {
    RESET_APP_SETTINGS,
    SET_API_BASE_PATH,
    SET_API_HOSTNAME,
    SET_API_PORT,
    SET_API_PROTOCOL
} from "../action-types/appSettings";

export const setApiBasePath = (apiBasePath) => ({
    type: SET_API_BASE_PATH,
    apiBasePath
});

export const setApiHostname = (apiHostname) => ({
    type: SET_API_HOSTNAME,
    apiHostname
});

export const setApiPort = (apiPort) => ({
    type: SET_API_PORT,
    apiPort
});

export const setApiProtocol = (apiProtocol) => ({
    type: SET_API_PROTOCOL,
    apiProtocol
});

export const resetAppSettings = () => ({
    type: RESET_APP_SETTINGS
});