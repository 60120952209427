import {
    RESET_SENSORS,
    SET_SENSORS_TASK_FAILURE,
    SET_SENSORS_TASK_PENDING,
    SET_SENSORS_TASK_SUCCESSFUL,
    SET_SENSORS_TYPES,
    SET_SENSORS_VALUES
} from "../action-types/sensors";

const initialState = {
    values: [],
    types: [],
    pending: false,
    error: null
};

export const sensors = (state = initialState, action) => {
    switch (action.type) {
        case SET_SENSORS_VALUES: {
            return {
                ...state,
                values: action.values
            }
        }
        case SET_SENSORS_TYPES: {
            return {
                ...state,
                types: action.types
            }
        }
        case SET_SENSORS_TASK_PENDING:
            return {
                ...state,
                pending: true
            };
        case SET_SENSORS_TASK_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case SET_SENSORS_TASK_SUCCESSFUL:
            return {
                ...state,
                pending: false,
                error: initialState.error
            };
        case RESET_SENSORS:
            return initialState;
        default:
            return state;
    }
};