import {
    RESET_TOOLBOXES,
    SET_TOOLBOXES_AVAILABLE,
    SET_TOOLBOXES_CURRENT,
    SET_TOOLBOXES_TASK_FAILURE,
    SET_TOOLBOXES_TASK_PENDING,
    SET_TOOLBOXES_TASK_SUCCESSFUL
} from "../action-types/toolboxes";

const initialState = {
    current: {
        id: -1,
        title: '',
        customerId: -1,
        sensors: []
    },
    available: [],
    pending: false,
    error: null
};

export const toolboxes = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOOLBOXES_AVAILABLE: {
            return {
                ...state,
                available: action.available
            }
        }
        case SET_TOOLBOXES_CURRENT: {
            return {
                ...state,
                current: action.current
            }
        }
        case SET_TOOLBOXES_TASK_PENDING:
            return {
                ...state,
                pending: true
            };
        case SET_TOOLBOXES_TASK_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case SET_TOOLBOXES_TASK_SUCCESSFUL:
            return {
                ...state,
                pending: false,
                error: initialState.error
            };
        case RESET_TOOLBOXES:
            return initialState;
        default:
            return state;
    }
};