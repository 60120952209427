import * as constants from "../action-types/roles";

const initialState = {
  data: null,
  error: null,
  pending: false
};

export const roles = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ROLES_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.GET_ROLES_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.GET_ROLES_SUCCESSFUL:
      return {
        ...state,
        data: action.roles,
        pending: false,
        error: initialState.error
      };
    default:
      return state;
  }
};
