import "core-js/stable";
import "isomorphic-fetch"
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./assets/base.scss";
import {history, persistor, store} from "./lib/redux/store/configureStore";
import {ConnectedApp} from "./App";

const rootElement = document.getElementById("root");

ReactDOM.render(
    <ConnectedApp store={store} persistor={persistor} history={history} />,
    rootElement
);

serviceWorker.unregister();
