import {httpService} from './httpService';

export class dashboardService extends httpService {
    _requestPath = '/dashboards';

    constructor() {
        super();
    }

    async get() {
        try {
            return await this.httpGet();
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async getById(id) {
        try {
            return await this.httpGet(`/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
//  http://localhost:5000/api/v1/dashboards/1/values/7?getRealValues=true&p=Month
    async getDiagramValues(dashboardId, sensorId, timespan, realValues) {
        try {
            return await this.httpGet(`/${dashboardId}/values/${sensorId}?p=${timespan}&getRealValues=${realValues}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
    async create(customerId, title, sortIndex) {
        try {
            const createUser = {
                customerId: customerId,
                title: title,
                sortIndex: sortIndex
            };
            return await this.httpPost(createUser);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
    async update(dashboard) {
        try {
            return await this.httpPut(dashboard);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    async updateTitle(id, title) {
        try {
            const updateDashboardTitle = {
                id: id,
                title: title
            };
            return await this.httpPut(updateDashboardTitle, `/title`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
    async delete(id) {
        try {
            return await this.httpDelete({}, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
}