export const ADD_DASHBOARD_LINK = 'ADD_DASHBOARD_LINK';
export const REMOVE_DASHBOARD_LINK = 'REMOVE_DASHBOARD_LINK';
export const REMOVE_DASHBOARD_LINK_BY_INDEX = 'REMOVE_DASHBOARD_LINK_BY_INDEX';
export const SET_DASHBOARD_LINK = 'SET_DASHBOARD_LINK';

export const ADD_DASHBOARD_WIDGET = 'ADD_DASHBOARD_WIDGET';
export const REMOVE_DASHBOARD_WIDGET_BY_ID = 'REMOVE_DASHBOARD_WIDGET_BY_ID';
export const UPDATE_DASHBOARD_WIDGET_LAYOUTS = 'UPDATE_DASHBOARD_WIDGET_LAYOUTS';

export const SET_DASHBOARD_CURRENT = 'SET_DASHBOARD_CURRENT';
export const SET_DASHBOARD_CURRENT_WIDGET = 'SET_DASHBOARD_CURRENT_WIDGET';
export const SET_DASHBOARD_CURRENT_WIDGET_TITLE = 'SET_DASHBOARD_CURRENT_WIDGET_TITLE';
export const SET_DASHBOARD_CURRENT_WIDGET_DESCRIPTION = 'SET_DASHBOARD_CURRENT_WIDGET_DESCRIPTION';
export const SET_DASHBOARD_CURRENT_WIDGET_TYPE = 'SET_DASHBOARD_CURRENT_WIDGET_TYPE';
export const SET_DASHBOARD_CURRENT_WIDGET_SETTINGS = 'SET_DASHBOARD_CURRENT_WIDGET_SETTINGS';
export const SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_ICON = 'SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_ICON';
export const SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_COMPONENT_TYPE_DATA = 'SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_COMPONENT_TYPE_DATA';
export const SET_DASHBOARD_CURRENT_WIDGET_ORIENTATION = 'SET_DASHBOARD_CURRENT_WIDGET_ORIENTATION';
export const SET_DASHBOARD_CURRENT_WIDGETS = 'SET_DASHBOARD_CURRENT_WIDGETS';

export const SET_DASHBOARDS_AVAILABLE = 'SET_DASHBOARD_AVAILABLE';

export const SET_DASHBOARDS_TASK_PENDING = 'SET_DASHBOARDS_TASK_PENDING';
export const SET_DASHBOARDS_TASK_FAILURE = 'SET_DASHBOARDS_TASK_FAILURE';
export const SET_DASHBOARDS_TASK_SUCCESSFUL = 'SET_DASHBOARDS_TASK_SUCCESSFUL';

export const RESET_DASHBOARDS = 'RESET_DASHBOARDS';
export const RESET_DASHBOARDS_CURRENT = 'RESET_DASHBOARDS_CURRENT';

export const ADD_DASHBOARD_CURRENT_WIDGET_CHILD = 'ADD_DASHBOARD_CURRENT_WIDGET_CHILD';
export const REMOVE_DASHBOARD_WIDGET_CHILD = 'REMOVE_DASHBOARD_WIDGET_CHILD';
export const ADD_DASHBOARD_WIDGET_CHILD = 'ADD_DASHBOARD_WIDGET_CHILD';

export const SET_DASHBOARD_CURRENT_WIDGET_LAYOUT = 'SET_DASHBOARD_CURRENT_WIDGET_LAYOUT';