import React, {Suspense} from 'react';
import {Switch} from 'react-router-dom';
import {AuthorizedRoute} from './AuthorizedRoute';
import {UnauthorizedRoute} from './UnauthorizedRoute';
import {DefaultLoader} from './Loader';
import {availableRoutes} from './availableRoutes';
import {ConnectedRouter} from "connected-react-router";

export const Routes = (props) => {
    return (
        <ConnectedRouter history={props.history}>
                <Suspense fallback={<DefaultLoader />}>
                    <Switch>
                        <UnauthorizedRoute exact={true} path="/" component={availableRoutes.login} />
                        <UnauthorizedRoute path="/login" component={availableRoutes.login} />
                        <UnauthorizedRoute path="/register" component={availableRoutes.register} />
                        <UnauthorizedRoute path="/dashboards/link/:key" component={availableRoutes.dashboards} />
                        <UnauthorizedRoute path="/forgot-password" component={availableRoutes.forgotPassword} />
                        <UnauthorizedRoute path="/reset-password" component={availableRoutes.resetPassword} />
                        <AuthorizedRoute path="/dashboards" component={availableRoutes.dashboards} />
                        <AuthorizedRoute path="/admin" component={availableRoutes.AdminCustomerUsers} />
                    </Switch>
                </Suspense>
        </ConnectedRouter>
    );
};