import {auth} from './auth';
import {appSettings} from './appSettings';
import {dashboards} from './dashboards';
import {themeOptions} from './themeOptions';
import {users} from './users';
import {customers} from './customers';
import {roles} from './roles';
import {sensors} from "./sensors";
import {statistics} from "./statistics";
import {widgets} from "./widgets";
import {toolboxes} from "./toolboxes";
import {tempAuth} from "./tempAuth";

export default {
    auth,
    appSettings,
    dashboards,
    themeOptions,
    users,
    customers,
    roles,
    sensors,
    statistics,
    widgets,
    toolboxes,
    tempAuth
};