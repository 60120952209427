import {getApiUrl, getAuthToken} from '../../helpers/http';

export class httpService {
    request;
    _requestPath = '';
    _requestUrl = ''; 

    constructor() {
        const defaults = require('superagent-defaults');
        this.request = defaults();
        const authToken = getAuthToken();
        if (authToken !== null)
            this.request.set('Authorization', `bearer ${authToken}`);
        this._setRequestUrl();
    }

    httpGet(appendRequestPath = '') {
        const url = this._getRequestUrl(appendRequestPath);
        return this.request.get(url);
    }

    httpPost(data = {}, appendRequestPath = '') {
        const url = this._getRequestUrl(appendRequestPath);
        return this.request
            .post(url)
            .send(data);
    }

    httpPut(data = {}, appendRequestPath = '') {
        const url = this._getRequestUrl(appendRequestPath);
        return this.request
            .put(url)
            .send(data);
    }

    httpDelete(data = {}, appendRequestPath = '') {
        const url = this._getRequestUrl(appendRequestPath);
        return this.request
            .del(url)
            .send(data);
    }

    _getRequestUrl(appendRequestPath = '') {
        this._setRequestUrl();
        return `${this._requestUrl}${appendRequestPath}`;
    }

    _setRequestUrl() {
        this._requestUrl = `${getApiUrl()}${this._requestPath}`;
    }
}
