import React, {useEffect} from "react";
import {connect, Provider} from "react-redux";
import {Routes} from "./Routes";
import {setApiBasePath, setApiHostname, setApiPort, setApiProtocol} from "./lib/redux/actions/appSettings";
import {PersistGate} from "redux-persist/integration/react";

import ResizeDetector from 'react-resize-detector'
import cx from 'classnames';
import {getIconColors} from "./lib/redux/actions/themeOptions";

const mapDispatchToProps = dispatch => ({
    setApiBasePath: path => dispatch(setApiBasePath(path)),
    setApiHostname: host => dispatch(setApiHostname(host)),
    setApiProtocol: protocol => dispatch(setApiProtocol(protocol)),
    setApiPort: port => dispatch(setApiPort(port)),
    setThemeOptionsIconColors: () => dispatch(getIconColors())
});

const App = props => {
    useEffect(() => {
        const apiBasePath = "/api/v1";
        const apiHostname = document.location.hostname;
        const apiPort = /localhost/.test(apiHostname) ? 5000 : 80;
        const apiProtocol =
            window.location.protocol === "https:" ? "https://" : "http://";
        // Store app settings
        props.setApiBasePath(apiBasePath);
        props.setApiHostname(apiHostname);
        props.setApiProtocol(apiProtocol);
        props.setApiPort(apiPort);
        props.setThemeOptionsIconColors();
    }, []);

    return (
        <Provider store={props.store}>
            <PersistGate loading={null} persistor={props.persistor}>
                <ResizeDetector handleWidth render={({ width }) => (
                    <div className={cx('app-container app-theme-' + props.colorScheme,
                        { 'fixed-header': props.enableFixedHeader },
                        { 'fixed-sidebar': (props.enableFixedSidebar || width < 1200) && !props.kiosk },
                        { 'fixed-footer': props.enableFixedFooter },
                        { 'closed-sidebar': (props.enableClosedSidebar || width < 1200) && !props.kiosk && !(props.routerPath==='/admin')},
                        { 'closed-sidebar-mobile': props.closedSmallerSidebar || width < 1200 },
                        { 'sidebar-mobile-open': props.enableMobileMenu },
                        { 'body-tabs-shadow-btn': props.enablePageTabsAlt },
                        { 'admin-page': props.routerPath==='/admin'})}>
                        <Routes history={props.history} />
                    </div>
                )}
                />
            </PersistGate>
        </Provider>
    );
};

const mapStateToProp = state => ({
    kiosk: state.dashboards.kiosk,
    routerPath: state.router.location.pathname,
    colorScheme: state.themeOptions.colorScheme,
    enableFixedHeader: state.themeOptions.enableFixedHeader,
    enableMobileMenu: state.themeOptions.enableMobileMenu,
    enableFixedFooter: state.themeOptions.enableFixedFooter,
    enableFixedSidebar: state.themeOptions.enableFixedSidebar,
    enableClosedSidebar: state.themeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.themeOptions.enablePageTabsAlt,
});



export const ConnectedApp = connect(
    mapStateToProp,
    mapDispatchToProps
)(App);
