import * as constants from "../action-types/customers";

const initialState = {
  data: null,
  error: null,
  pending: false
};

export const customers = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.GET_CUSTOMERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.GET_CUSTOMERS_SUCCESSFUL:
      return {
        ...state,
        data: action.customers,
        pending: false
      };

      case constants.CREATE_CUSTOMER_FAILURE:
        return {
          ...state,
          error: action.error,
          pending: false
        };
      case constants.CREATE_CUSTOMER_PENDING:
        return {
          ...state,
          pending: true
        };
      case constants.CREATE_CUSTOMER_SUCCESSFUL:
        return {
          ...state,
          data: state.data.concat(action.customer),
          pending: false,
          error: initialState.error
        };

    case constants.UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.UPDATE_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.UPDATE_CUSTOMER_SUCCESSFUL:
      return {
        ...state,
        data: state.data.map(customer =>
            customer.id === action.customer.id
                ? {
                  ...customer,
                  name: action.customer.name,
                  dataLogin: action.customer.dataLogin,
                  errorReportingEmail: action.customer.errorReportingEmail
                }
                : customer
        ),
        pending: false,
        error: initialState.error
      };

    case constants.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.DELETE_CUSTOMER_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.DELETE_CUSTOMER_SUCCESSFUL:
      return {
        ...state,
        data: state.data.filter(function(customer) {
          return customer.id !== action.customerId;
        }),
        pending: false
      };

    case constants.RESET_CUSTOMERS:
      return initialState;
    default:
      return state;
  }
};
