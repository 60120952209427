import {httpService} from './httpService';
import {store} from '../../redux/store';
import {push} from 'connected-react-router';
import _ from 'lodash';

export class authenticateService extends httpService {
    _requestPath = '/authenticate';

    constructor() {
        super();
    }
    
    isLoggedIn() {
        // TODO: Implement better control
        return _.get(store.getState().auth, 'user.customerId', false) || _.get(store.getState().tempAuth, 'user.customerId', false);
    }

    async login(email, password) {
        try {
            return await this.httpPost({ email: email, password: password }, '/login');
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }

    logout () {
        // TODO: Implement better solution. (UGLY)
        store.dispatch({type:'RESET_AUTH'});
        store.dispatch({type:'RESET_TEMP_AUTH'});
        store.dispatch({type:"RESET_CUSTOMERS"});
        store.dispatch({type:'RESET_DASHBOARDS'});
        store.dispatch({type:'RESET_SENSORS'});
        store.dispatch({type:'RESET_TOOLBOXES'});
        store.dispatch({type:'RESET_WIDGETS'});
        store.dispatch({type:'RESET_USERS'});
        store.dispatch(push('/login'));
    }
}