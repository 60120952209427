import * as constants from "../action-types/users";

const initialState = {
  data: null,
  error: null,
  pending: false
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.GET_USERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.GET_USERS_SUCCESSFUL:
      return {
        ...state,
        data: action.users,
        pending: false
      };
    case constants.DELETE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.DELETE_USERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.DELETE_USERS_SUCCESSFUL:
      return {
        ...state,
        data: state.data.filter(function(user) {
          return user.id !== action.userId;
        }),
        pending: false
      };

    case constants.UPDATE_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false
      };
    case constants.UPDATE_USERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case constants.UPDATE_USERS_SUCCESSFUL:
      return {
        ...state,
        data: state.data.map(user =>
          user.id === action.user.id
            ? {
                ...users,
                ...user,
                firstName: action.user.firstName,
                lastName: action.user.lastName,
                email: action.user.email,
                mobilePhone: action.user.mobilePhone,
                role: action.user.role
              }
            : user
        ),
        pending: false,
        error: initialState.error
      };
    case constants.RESET_USERS:
      return initialState;
    default:
      return state;
  }
};
