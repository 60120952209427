import {httpService} from './httpService';

export class roleService extends httpService {
    _requestPath = '/roles';

    constructor() {
        super();
    }

    async get() {
        try {
            return await this.httpGet();
        }
        catch (e) {
            console.error(e);
            return {};
        }
    }
    
}