import {
    CHANGE_CUSTOMER_ID,
    RESET_TEMP_AUTH,
    TEMP_LOGIN_USER_FAILURE,
    TEMP_LOGIN_USER_PENDING,
    TEMP_LOGIN_USER_SUCCESSFUL
} from "../action-types/auth";

const initialState = {
    user: null,
    error: null,
    pending: false
};

export const tempAuth = (state = initialState, action) => {
    switch (action.type) {
        case TEMP_LOGIN_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                pending: false
            };
        case TEMP_LOGIN_USER_PENDING:
            return {
                ...state,
                pending: true
            };
        case TEMP_LOGIN_USER_SUCCESSFUL:
            return {
                ...state,
                user: action.user,
                pending: false,
                error: initialState.error
            };
        case CHANGE_CUSTOMER_ID:
            return state.user ? {
                ...state,
                user: {...state.user, customerId: action.customerId}
            } : {...state};
        case RESET_TEMP_AUTH:
            return initialState;
        default:
            return state;
    }
};