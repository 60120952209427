import {applyMiddleware, compose, createStore} from "redux";
import {persistCombineReducers, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import reducers from "../reducers";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";

export const history = createBrowserHistory({basename: '/'});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth","themeOptions"]
};

const logger = createLogger({
    level: "info"
});

const createRootReducer = history =>
    persistCombineReducers(persistConfig, {
        router: connectRouter(history),
        ...reducers
    });

const middlewares = [
    logger, 
    thunk, 
    routerMiddleware(history)
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadState =>
    createStore(
        createRootReducer(history),
        preloadState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

export const store = configureStore();
export const persistor = persistStore(store);
