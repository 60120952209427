export const RESET_USERS = 'RESET_USERS';
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_SUCCESSFUL = 'GET_USERS_SUCCESSFUL';

export const DELETE_USERS_PENDING = 'DELETE_USERS_PENDING';
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE';
export const DELETE_USERS_SUCCESSFUL = 'DELETE_USERS_SUCCESSFUL';

export const UPDATE_USERS_PENDING = 'UPDATE_USERS_PENDING';
export const UPDATE_USERS_FAILURE = 'UPDATE_USERS_FAILURE';
export const UPDATE_USERS_SUCCESSFUL = 'UPDATE_USERS_SUCCESSFUL';