import {httpService} from './httpService';

export class userService extends httpService {
    _requestPath = '/users';

    constructor() {
        super();
    }

    async get(id) {
        try {
            return await this.httpGet(`/${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async create(firstname, lastname, mobilephone, culture, password, key) {
        try {
            const createUser = {
                firstName: firstname,
                lastName: lastname,
                mobilePhone: mobilephone,
                culture: culture,
                password: password,
                confirmationURL: key
            };
            return await this.httpPost(createUser, '/register');
        }
        catch (e) {
            return e;
        }
    }

    async createApiUser(user) {
        try {
            return await this.httpPost(user, '/createapiuser');
        }
        catch (e) {
            return e;
        }
    }

    async invite(email, roleId, customerId) {
        try {
            const invitation = {
                email: email,
                roleId: roleId,
                customerId: customerId
            };
            return await this.httpPost(invitation,'/inviteuser');
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async update(id, firstname, lastname, mobilephone, culture, roleId, isApiAccount, password) {
        try {
            const updateUser = {
                firstName: firstname,
                lastName: lastname,
                mobilePhone: mobilephone,
                culture: culture,
                roleId: roleId,
                isApiAccount: isApiAccount,
                password: password
            };
            return await this.httpPut(updateUser, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async delete(id) {
        try {
            return await this.httpDelete({}, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async getUsers(id) {
        try {
            return await this.httpGet(`?customerId=${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async getEmailFromRegURL(key) {
        try {
            let obj = {
                confirmationURL: key
            };
            return await this.httpPost(obj,'/verifyconfirmationurl');
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async forgotPassword(email) {
        try {
            return await this.httpPost({email: email},'/forgot-password');
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }
    async resetPassword(password, key) {
        try {
            return await this.httpPost({password: password, key: key},'/reset-password');
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }
}