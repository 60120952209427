export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_SUCCESSFUL = 'LOGIN_USER_SUCCESSFUL';

export const TEMP_LOGIN_USER_PENDING = 'TEMP_LOGIN_USER_PENDING';
export const TEMP_LOGIN_USER_FAILURE = 'TEMP_LOGIN_USER_FAILURE';
export const TEMP_LOGIN_USER_SUCCESSFUL = 'TEMP_LOGIN_USER_SUCCESSFUL';

export const SET_AUTH_USER_BEARER_TOKEN = 'SET_AUTH_USER_BEARER_TOKEN';

export const RESET_AUTH = 'RESET_AUTH';
export const RESET_TEMP_AUTH = 'RESET_TEMP_AUTH';
export const CHANGE_CUSTOMER_ID = 'CHANGE_CUSTOMER_ID';