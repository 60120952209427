import {httpService} from './httpService';

export class customerService extends httpService {
    _requestPath = '/customers';

    constructor() {
        super();
    }

    async get(customerId) {
        try {
            return await this.httpGet(`/${customerId}`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async delete(id) {
        try {
            return await this.httpDelete({}, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async update(id, name, dataLogin, dataPassword, errorReportingEmail) {
        try {
            const updateCustomer = {
                dataLogin: dataLogin,
                dataPassword: dataPassword,
                name: name,
                errorReportingEmail: errorReportingEmail
            };
            return await this.httpPut(updateCustomer, `/${id}`);
        }
        catch (e) {
            console.error(e);
            return e;
        }
    }

    async getToolboxesAvailable(customerId) {
        try {
            return await this.httpGet(`/${customerId}/toolboxes`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async getToolboxSensors(customerId, toolboxId) {
        try {
            return await this.httpGet(`/${customerId}/toolboxes/${toolboxId}`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async getCustomerList() {
        try {
            return await this.httpGet();
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async getUsersByCustomerId(customerId) {
        try {
            return this.httpGet(`/${customerId}/users`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async create(name, dataLogin, dataPassword, errorReportingEmail) {
        try {
            const customer = {
                name: name,
                dataLogin: dataLogin,
                dataPassword: dataPassword,
                errorReportingEmail: errorReportingEmail
            };
            return await this.httpPost(customer, '');
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async getToolbox(customerId, toolboxId) {
        try {
            return await this.httpGet(`/${customerId}/toolboxes/${toolboxId}`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async getSensorTree(customerId) {
        try {
            return await this.httpGet(`/${customerId}/sensortree`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }


    async saveToolbox(toolbox, customerId, toolboxId) {
        try {
            return await this.httpPut(toolbox, `/${customerId}/toolboxes/${toolboxId}`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async createToolbox(toolbox, customerId) {
        try {
            return await this.httpPost(toolbox, `/${customerId}/toolboxes/`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async testYggioLogin(username, password) {
        try {
            return await this.httpPost({dataLogin: username, dataPassword: password}, '/yggio/check/login');
        } catch (e) {
            console.error(e);
            return e;
        }
    }
    async getDashboards(customerId) {
        try {
            return await this.httpGet(`/${customerId}/dashboards`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }
}