import {
    ADD_DASHBOARD_CURRENT_WIDGET_CHILD,
    ADD_DASHBOARD_LINK,
    ADD_DASHBOARD_WIDGET,
    ADD_DASHBOARD_WIDGET_CHILD,
    REMOVE_DASHBOARD_LINK,
    REMOVE_DASHBOARD_LINK_BY_INDEX,
    REMOVE_DASHBOARD_WIDGET_BY_ID,
    REMOVE_DASHBOARD_WIDGET_CHILD,
    RESET_DASHBOARDS,
    RESET_DASHBOARDS_CURRENT,
    SET_DASHBOARD_CURRENT,
    SET_DASHBOARD_CURRENT_WIDGET,
    SET_DASHBOARD_CURRENT_WIDGET_DESCRIPTION,
    SET_DASHBOARD_CURRENT_WIDGET_LAYOUT,
    SET_DASHBOARD_CURRENT_WIDGET_ORIENTATION,
    SET_DASHBOARD_CURRENT_WIDGET_SETTINGS,
    SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_COMPONENT_TYPE_DATA,
    SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_ICON,
    SET_DASHBOARD_CURRENT_WIDGET_TITLE,
    SET_DASHBOARD_CURRENT_WIDGET_TYPE,
    SET_DASHBOARD_CURRENT_WIDGETS,
    SET_DASHBOARD_LINK,
    SET_DASHBOARDS_AVAILABLE,
    SET_DASHBOARDS_TASK_FAILURE,
    SET_DASHBOARDS_TASK_PENDING,
    SET_DASHBOARDS_TASK_SUCCESSFUL
} from "../action-types/dashboards";

const initialCurrentState= {
    pending: false
};
const initialState = {
    current: initialCurrentState,
    kiosk: false,
    available: [],
    pending: false,
    error: null
};

export const dashboards = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DASHBOARD_LINK: {
            // Add to available
            let availableDashboardState = state.available;
            const avIndex = state.available.findIndex(f => f.id === action.dashboardId);
            availableDashboardState[avIndex].dashboardLinks = [action.dashboardLink];
            
            // Add to current.dashboard
            const key = state.current.result;
            const dashboard = state.current.dashboard[key];
            const dashboardState = {
                [key]: {
                    ...dashboard,
                    dashboardLinks: dashboard.dashboardLinks ?  [...dashboard.dashboardLinks, action.dashboardLink] : []
                }
            };
            const currentState = {
                ...state.current,
                dashboard: dashboardState,
            };
            return {
                ...state,
                available: availableDashboardState,
                current: currentState
            };
        }
        case REMOVE_DASHBOARD_LINK: {
            // Remove from available.dashboardLinks
            let availableDashboardState = state.available;
            const avIndex = state.available.findIndex(f => f.id === action.dashboardId);
            const avdIndex = state.available[avIndex].dashboardLinks.findIndex(f => f.id === action.dashboardLinkId);
            const { [avdIndex]: value1, ...availableDashboardLinksState } = state.available[avIndex].dashboardLinks;
            availableDashboardState[avIndex].dashboardLinks = availableDashboardLinksState;
            
            // Remove from current dashboard
            const key = state.current.result;
            const dashboard = state.current.dashboard[key];
            const dashboardLinksState = dashboard.dashboardLinks.filter(f => f.id !== action.dashboardLinkId);
            const dashboardState = {
                [key]: {
                    ...dashboard,
                    dashboardLinks: dashboardLinksState
                }
            };
            const currentState = {
                ...state.current,
                dashboard: dashboardState
            };
            return {
                ...state,
                available: availableDashboardState,
                current: currentState
            }
        }
        case SET_DASHBOARD_LINK: {
            const index = state.dashboardLinks.findIndex(f => f.id === action.dashboardLinkId);
            return {
                ...state,
                dashboardLinks: {...state.dashboardLinks, [index]: action.dashboardLink}
            };
        }
        case REMOVE_DASHBOARD_LINK_BY_INDEX: {
            const { [action.dashboardLinkIndex]: value, ...dashboardLinks } = state.dashboardLinks;
            return {
                ...state,
                dashboardLinks: dashboardLinks
            };
        }
        case ADD_DASHBOARD_WIDGET: {
            // Get the dashboard normalization key
            const key = state.current.result;
            // Append new widget to widgets state
            const widgetsState = {
                ...state.current.widgets,
                [action.widget.id]: action.widget
            };
            // Append widget id to dashboard[key].widgets
            const dashboardState = { 
                [key]: {
                    ...state.current.dashboard[key], 
                    widgets:  state.current.dashboard[key].widgets.concat(action.widget.id)
                }
            };
            // Create new current state of dashboard
            const currentState = {
                ...state.current,
                dashboard: dashboardState,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }

        case ADD_DASHBOARD_WIDGET_CHILD: {
            const key = state.current.result;
            const widgetsState = {
                ...state.current.widgets,
                [action.widget.id]: action.widget
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }

        case REMOVE_DASHBOARD_WIDGET_CHILD: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetChildren: state.current.widgets[action.widgetId].widgetChildren.filter(id => id !== action.childId)
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }

        case REMOVE_DASHBOARD_WIDGET_BY_ID: {
            // Get the dashboard normalization key
            const key = state.current.result;
            // Remove widget by id  from widgets
            const { [action.widgetId]: value, ...widgetsState } = state.current.widgets;
            // Remove widget by id from dashboard
            const dashboardState = {
                [key]: {
                    ...state.current.dashboard[key],
                    widgets:  state.current.dashboard[key].widgets.filter(w => w !== action.widgetId)
                }
            };
            // Create new current state
            const currentState = {
                ...state.current,
                dashboard: dashboardState,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT: {
            return {
                ...state,
                current: action.current,
                kiosk: action.kiosk
            }
        }
        case SET_DASHBOARD_CURRENT_WIDGET: {
            const currentState = {
                ...state.current,
                [action.widget.id]: action.widget
            };
            return {
                ...state,
                current: currentState
            }
        }
        case SET_DASHBOARD_CURRENT_WIDGET_DESCRIPTION: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetSettings: {
                    ...state.current.widgets[action.widgetId].widgetSettings,
                    description: action.description
                }
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT_WIDGET_TITLE: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                title: action.title
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }

        case ADD_DASHBOARD_CURRENT_WIDGET_CHILD: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetChildren: state.current.widgets[action.widgetId].widgetChildren.concat(action.child)
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }

        case SET_DASHBOARD_CURRENT_WIDGET_ORIENTATION: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetSettings: {
                    ...state.current.widgets[action.widgetId].widgetSettings,
                    orientation: action.orientation
                }
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }        
        case SET_DASHBOARD_CURRENT_WIDGET_TYPE: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetType: action.widgetType
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT_WIDGETS: {
            const currentState = {
                ...state.current,
                widgets: action.widgets
            };
            return {
                ...state,
                current: currentState
            }
        }
        case SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_ICON: {
            const widget = state.current.widgets[action.widgetId];
            const widgetState = {
                ...widget,
                widgetSettings: {
                    ...widget.widgetSettings,
                    icon: action.widgetSettingsIcon
                }
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT_WIDGET_SETTINGS: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetSettings: action.widgetSettings
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT_WIDGET_LAYOUT: {
            const widgetState = {
                ...state.current.widgets[action.widgetId],
                widgetLayout: action.widgetLayout
            };
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARD_CURRENT_WIDGET_SETTINGS_COMPONENT_TYPE_DATA: {
            // TODO: Bad code. Should be moved to action.
            const componentTypeData = state.current.widgets[action.widgetId].widgetSettings.componentTypeData || [];
            const index = componentTypeData.findIndex(f => f.componentType === action.componentType);
            let widgetState = state.current.widgets[action.widgetId];
            if(index > -1) {
                widgetState.widgetSettings.componentTypeData[index] = action.componentTypeData;
            } else {
                widgetState = {
                    ...widgetState,
                    widgetSettings: {
                        ...widgetState.widgetSettings,
                        componentTypeData: [...componentTypeData, action.componentTypeData]
                    }
                };
            }
            const widgetsState = {
                ...state.current.widgets,
                [action.widgetId]: widgetState
            };
            const currentState = {
                ...state.current,
                widgets: widgetsState
            };
            return {
                ...state,
                current: currentState
            };
        }
        case SET_DASHBOARDS_AVAILABLE: {
            return {
                ...state,
                available: action.available
            }
        }
        case SET_DASHBOARDS_TASK_PENDING:
            return {
                ...state,
                pending: true
            };
        case SET_DASHBOARDS_TASK_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case SET_DASHBOARDS_TASK_SUCCESSFUL:
            return {
                ...state,
                pending: false,
                error: initialState.error
            };
        case RESET_DASHBOARDS:
            return initialState;
        case RESET_DASHBOARDS_CURRENT:
            return {
                ...state,
                current: initialState.current
            };
        default:
            return state;
    }
};
