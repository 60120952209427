import {httpService} from './httpService';

export class sensorService extends httpService {
    _requestPath = '/sensors';

    constructor() {
        super();
    }

    async get() {
        try {
            return await this.httpGet();
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getByCustomerId(customerId) {
        try {
            return await this.httpGet(`/${customerId}`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getValues() {
        try {
            return await this.httpGet(`/values`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getSensorHistory(sensorId, startDate, pageIndex, pageSize) {
        try {
            return await this.httpGet(`/${sensorId}/history?startDate=${startDate}&currentPageIndex=${pageIndex}&takeSize=${pageSize}`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getValuesByCustomerId(customerId) {
        try {
            return await this.httpGet(`/values/${customerId}`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getTypes() {
        try {
            return await this.httpGet(`/types`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async info(id) {
        try {
            return await this.httpGet(`/${id}/sensorInfo`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async updateSearchFilter(id, widgetId, widgetComponentName, sensorId, startDate, interval, getRealValues, trailingTimespan) {
        try {
            return await this.httpPut({
                id: id,
                widgetId: widgetId,
                widgetComponentName: widgetComponentName,
                sensorId: sensorId,
                startDate: startDate,
                interval: interval,
                getRealValues: getRealValues,
                trailingTimespan: trailingTimespan
            }, `/searchfilter`);
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    async getTimeout(sensorId) {
        try {
            return await this.httpGet(`/${sensorId}/timeout`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    async setTimeout(sensorId, inherited, timeoutActive, automaticAdjustmentActive, timeoutInSeconds) {
        try {
            return await this.httpPut({
                inherited: inherited,
                timeoutActive: timeoutActive,
                automaticAdjustmentActive: automaticAdjustmentActive,
                timeoutInSeconds: timeoutInSeconds
            }, `/${sensorId}/timeout`);
        } catch (e) {
            console.error(e);
            return e;
        }
    }
}
