import { httpService } from "./httpService";

export class errorReportingService extends httpService {
  _requestPath = "/errorReporting";

  async post(customerId, name, email, telephone, message) {
    try {
      const errorReport = {
        customerId: customerId,
        name: name,
        email: email,
        telephone: telephone,
        message: message,
      };
      return await this.httpPost(errorReport, "/");
    } catch (e) {
      return e;
    }
  }
}
