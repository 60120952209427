import {
    RESET_APP_SETTINGS,
    SET_API_BASE_PATH,
    SET_API_HOSTNAME,
    SET_API_PORT,
    SET_API_PROTOCOL
} from "../action-types/appSettings";

const initialState = {
    apiBasePath: '',
    apiHostname: '',
    apiProtocol: '',
    apiPort: 80,
};

export const appSettings = (state = initialState, action) => {
    switch (action.type) {
        case SET_API_BASE_PATH:
            return {
                ...state,
                apiBasePath: action.apiBasePath
            }
        case SET_API_HOSTNAME:
            return {
                ...state,
                apiHostname: action.apiHostname
            }
        case SET_API_PORT:
            return {
                ...state,
                apiPort: action.apiPort
            }
        case SET_API_PROTOCOL:
            return {
                ...state,
                apiProtocol: action.apiProtocol
            }
        case RESET_APP_SETTINGS:
            return initialState
        default:
            return state;
    }
}