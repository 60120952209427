import React from 'react';
import Loader from 'react-loaders'

export const DefaultLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-pulse-rise"  active />
                </div>
                <h6 className="mt-5">
                    Vänta medan sidan laddar.
                </h6>
            </div>
        </div>
    );
}